import "@sg-widgets/react-webpack/docs-ui/docs-ui-entry"

const style = document.createElement('style')
style.innerHTML = `
/* 
    Utile pour le nouveau bearer token qui fait 15km de long. Sans ça, la page fait aussi 15km de large. 
    Si ton widget est centré par rapport à son container, il se retrouve perdu au milieu de nulle part.
    Si ton widget prend toute la largeur de son container, il va lui aussi faire 15km de long.
*/
#docs-ui-root > div.container-fluid > div.container-fluid > div.d-flex > div:nth-child(2) {
    /* Correspond au bloc "principal" de la page */
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
  
#docs-ui-root > div.container-fluid > div.container-fluid > div.d-flex > div:nth-child(2) > div.card > div.card-body {
    /* Correspond aux éléments du type <DocsUi.qqch /> présents dans les readme */
    overflow: scroll;
}

#docs-ui-root > div.container-fluid > div.container-fluid > div.d-flex > div:nth-child(2) > div.card > div.card-body > .card-text > table.table td:nth-child(1) {
    /* Correspond à la 1ère colonne des tableaux présents dans les éléments du type <DocsUi.qqch /> */
    white-space: nowrap;
}

/*
    Utile pour permettre la modification de la taille des blocs "Preview" directement depuis le navigateur
*/
#docs-ui-root > div.container-fluid > div.container-fluid > div.d-flex > div:nth-child(2) > div > div.border > div.border.border-socgen {
    /* Correspond aux éléments "Preview" dans les readme */
    resize: both;
    overflow: auto;
}
`
document.body.appendChild(style);

const sgBootstrapKey = '__sg-widgets__sg-bootstrap-version';

const baseUrl = window.__SG_WIDGETS_DOCS_UI_WIDGET_PROJECT__.baseUrl;

const bootstrapVersions = {
    'v4@latest': {
        coreUrl: `${baseUrl}/sg-bootstrap/v4/latest/sg-bootstrap-standard.min.css`,
        iconsUrl: `${baseUrl}/sg-bootstrap/v4/icons/index.min.css`,
        isDefault: true,
    },
    'v5@5.5.1': {
        coreUrl: `${baseUrl}/sg-bootstrap/v5/5.5.1/core/css/sg-bootstrap-standard.min.css`,
        iconsUrl: `${baseUrl}/sg-bootstrap/v5/5.5.1/icons/index.min.css`,
    }
}

const isClickedOutside = (evt, element) => {
    let targetEl = evt.target;
    do {
        if (targetEl === element) {
            // This is a click inside element
            return false;
        }
        // Go up the DOM
        targetEl = targetEl.parentNode;
    } while (targetEl);
    return true;
};

const changeBootstrapVersion = (bsKey, bsButton) => {
    const allCssCore = Object.keys(bootstrapVersions).map(x => bootstrapVersions[x].coreUrl);
    const allCssIcons = Object.keys(bootstrapVersions).map(x => bootstrapVersions[x].iconsUrl);
    document.querySelectorAll('head link').forEach((css) => {
        const cssHref = css.getAttribute('href');
        if (allCssCore.find(cssCore => cssCore === cssHref)) {
            css.setAttribute('href', bootstrapVersions[bsKey].coreUrl);
        }
        if (allCssIcons.find(cssIcon => cssIcon === cssHref)) {
            css.setAttribute('href', bootstrapVersions[bsKey].iconsUrl);
        }
    });

    const newSharedUrls = window.SGWTWidgetConfiguration.sharedCssUrls.map(url => {
        if (allCssCore.find(cssCore => cssCore === url)) {
            return bootstrapVersions[bsKey].coreUrl;
        }
        if (allCssIcons.find(cssIcon => cssIcon === url)) {
            return bootstrapVersions[bsKey].iconsUrl;
        }
        return url;
    })
    window.SGWTWidgetConfiguration.update({ sharedCssUrls: newSharedUrls });
    window.SGWTWidgetConfiguration.bus.publish(sgBootstrapKey, bsKey);

    window.localStorage.setItem(sgBootstrapKey, bsKey);

    bsButton.parentNode.querySelectorAll('button').forEach(bt => bt.classList.remove('active'));
    bsButton.classList.add('active');
};

const initBootstrapBehavior = () => {
    const bsDropdown = document.createElement('li');
    bsDropdown.classList.add('dropdown', 'nav-item');

    const bsDropdownText = document.createElement('a');
    bsDropdownText.classList.add('dropdown-toggle', 'nav-link');
    bsDropdownText.setAttribute('href', '#');
    bsDropdownText.innerHTML = 'Bootstrap';
    bsDropdown.appendChild(bsDropdownText);

    const bsDropdownContent = document.createElement('div');
    bsDropdownContent.classList.add('dropdown-menu');
    bsDropdownContent.setAttribute('role', 'menu');
    bsDropdownContent.setAttribute('tabindex', '-1');
    bsDropdown.appendChild(bsDropdownContent);

    Object.keys(bootstrapVersions).forEach((bsKey) => {
        const bsButton = document.createElement('button');
        bsButton.classList.add('dropdown-item');
        bsButton.setAttribute('role', 'menuitem');
        bsButton.setAttribute('data-sg-bootstrap-version', bsKey);
        if (bootstrapVersions[bsKey].isDefault) {
            bsButton.setAttribute('data-sg-bootstrap-default', true);
        }
        bsButton.innerHTML = bsKey;
        bsButton.onclick = () => changeBootstrapVersion(bsKey, bsButton);
        bsDropdownContent.appendChild(bsButton);
    })

    bsDropdown.onclick = (event) => {
        bsDropdown.classList.toggle('show');
        bsDropdownContent.classList.toggle('show');
        event.preventDefault();
    };

    const topMenu = document.querySelector('nav.navbar ul.navbar-nav');
    topMenu.insertBefore(bsDropdown, topMenu.lastChild);

    document.addEventListener('click', (evt) => {
        if (isClickedOutside(evt, bsDropdown)) {
            bsDropdown.classList.remove('show');
            bsDropdownContent.classList.remove('show');
        }
    });

    const storageItem = window.localStorage.getItem(sgBootstrapKey);
    if (Object.keys(bootstrapVersions).find(x => x === storageItem)) {
        const bsButton = bsDropdownContent.querySelector(`button[data-sg-bootstrap-version='${storageItem}']`);
        changeBootstrapVersion(storageItem, bsButton);
    } else {
        const bsButton = bsDropdownContent.querySelector(`button[data-sg-bootstrap-default]`);
        const defaultVersion = Object.keys(bootstrapVersions).find(x => bootstrapVersions[x].isDefault);
        changeBootstrapVersion(defaultVersion, bsButton);
    }
};

let retry = 0;
const deferredInit = () => {
    if (document.querySelector('nav.navbar ul.navbar-nav')?.lastChild) {
        initBootstrapBehavior();
    } else if (retry < 100) {
        retry++;
        setTimeout(deferredInit, 10);
    } else {
        console.error('Can\'t init bootstrap dropdown, please check custom-docs-ui-entry.js')
    }
};

(() => deferredInit())();